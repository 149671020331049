export const Form = {
  paddingTop: '20px',
  paddingLeft: '20px',
  clear: 'both',
}

export const FormSection = {
  clear: 'both',
  paddingBottom: '20px',
  marginBottom: '20px',
  borderBottom: '1px dotted #ccc',
}

export const SubHeader = {
  color: 'black',
  font: '400 16px Roboto,sans-serif',
  clear: 'both',
  marginBottom: '20px',
}

export const MiniSubHeader = {
  color: 'black',
  font: '400 14px Roboto,sans-serif',
  clear: 'both',
  margin: '10px 0',
}

export const Input = {
  width: '225px',
}

export const SmallInput = {
  width: '60px',
}

export const Select = {
  width: '165px',
}

export const RadioCheckBox = {
  cursor: 'pointer',
  left: '-20px',
  height: '20px',
  top: '-5px',
  width: '225px',
}

export const SaveButton = {
  marginTop: '20px',
}

export const Note = {
  color: 'rgb(153, 153, 153)',
  font: '400 italic 12px Roboto,sans-serif',
  fontStyle: 'italic !important',
  marginTop: '10px',
  marginBottom: '5px',
}

export const Caption = {
  color: 'rgb(153, 153, 153)',
  font: '400 normal 12px Roboto,sans-serif',
  marginBottom: '5px',
}

export const ThemeSwitch = {
  float: 'left',
  marginBottom: '10px',
  width: '100px',
}

export const ThemePreview = {
  clear: 'both',
  marginBottom: '10px',
}

export const TemplateImage = {
  height: '112px',
  marginTop: '10px',
}

export const SettingRow = {
  clear: 'both',
}

export const SettingCol = {
  width: '190px',
  float: 'left',
}

export const Block = {
  marginBottom: '20px',
}

export const SettingColTitle = { ...SettingCol, lineHeight: '10px' }

export const bgImageUpload = {
  position: 'relative',
  float: 'left',
  marginBottom: '10px',
  clear: 'left',
}

export const emptyPhoto = {
  background: `#f5f5f5 url(${MEDIA_URL}images/add_picture.png) 50% 50% no-repeat`,
  border: '1px dashed #ccc',
  cursor: 'pointer',
  float: 'left',
  position: 'relative',
}

export const hasPhoto = {
  border: '1px solid #fff',
  float: 'left',
  position: 'relative',
  overflow: 'hidden',
}

export const bigPhoto = {
  backgroundSize: '42px 32px',
  height: '200px',
  marginRight: '20px',
  marginTop: '10px',
  width: '400px',
}

export const actionAdjust = {
  height: '25px',
  width: '21px',
}

export const photoActions = {
  right: '4px',
  position: 'absolute',
  top: '4px',
}

export const photoStyle = {
  left: '-25%',
  position: 'absolute',
  width: '100%',
}

export const cropWrap = {
  background: '#fff',
  left: '50%',
  marginLeft: '-200px',
  position: 'fixed',
  top: '25%',
  width: '400px',
  zIndex: '1001',
}

export const action = {
  backgroundColor: '#f1f1f1',
  backgroundSize: '15px 15px',
  backgroundPosition: '50% 50%',
  backgroundRepeat: 'no-repeat',
  border: '1px solid #ccc',
  borderRadius: '3px',
  cursor: 'pointer',
  display: 'inline-block',
  height: '35px',
  marginLeft: '5px',
  marginTop: '2px',
  width: '31px',
}

export const headerStyle = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
}

export const headerTextStyle = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '80%',
}

export const closer = {
  cursor: 'pointer',
  fontSize: '41px',
  fontWeight: '100',
  position: 'absolute',
  right: '10px',
  top: '0px',
}

export const eg = {
  clear: 'both',
  color: '#aaa',
  fontSize: '10px',
  fontStyle: 'italic',
}

export const shadow = {
  background: '#000',
  height: '100%',
  left: '0',
  opacity: '0.8',
  position: 'fixed',
  top: '0',
  width: '100%',
  zIndex: '1000',
}

export const CharBox = {
  float: 'left',
  height: '25px',
  width: '62px',
  border: '1px solid #888888',
  borderRadius: '3px',
  paddingTop: '8px',
  opacity: '0.4',
  textAlign: 'center',
}

export const CharBoxFirst = { ...CharBox, borderRadius: '3px 0 0 3px' }

export const CharBoxLast = { ...CharBox, borderRadius: '0 3px 3px 0', borderLeft: 'none' }

export const BlueCharBox = {
  opacity: '0.8',
  backgroundColor: '#CFE0FE',
  color: '#1a5aca',
}

export const ButtonCaptionStyle = size => {
  const baseStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
  }
  let sizedStyle = {}
  switch (size) {
    case 'small':
      sizedStyle = {
        fontSize: '10px',
      }
      break
    case 'medium':
      sizedStyle = {
        fontSize: '11px',
      }
      break
    case 'large':
      sizedStyle = {
        fontSize: '13px',
        marginTop: '8px',
      }
      break
    default:
      break
  }
  return { ...baseStyle, ...sizedStyle }
}

export const ButtonStyle = (size, type, buttonColor, showWaitTimes = false) => {
  const baseStyle = {
    borderRadius: '3px',
    textAlign: 'center',
    boxSizing: 'border-box',
    lineHeight: '15px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
  const typeStyle =
    type === 'dark'
      ? {
          backgroundColor: buttonColor,
          color: 'white',
          fontWeight: '100',
        }
      : {
          backgroundColor: 'white',
          color: buttonColor,
          border: `1px solid ${buttonColor}`,
          fontWeight: '400',
        }
  let sizedStyle
  switch (size) {
    case 'small':
      sizedStyle = {
        padding: '5px 0px 7px 6px',
        width: '127px',
        fontSize: '12px',
        minHeight: showWaitTimes ? '45px' : '26px',
      }
      break
    case 'medium':
      sizedStyle = {
        padding: '9px 0px 12px 10px',
        width: '152px',
        fontSize: '14px',
        minHeight: showWaitTimes ? '50px' : '35px',
      }
      break
    case 'large':
      sizedStyle = {
        padding: '14px 0px 17px 10px',
        width: '178px',
        fontSize: '18px',
        minHeight: showWaitTimes ? '70px' : '45px',
      }
      break
    default:
      break
  }
  return { ...baseStyle, ...typeStyle, ...sizedStyle }
}

export const ButtonImg = size => {
  const baseStyle = {
    display: 'block',
    float: 'left',
  }
  let sizedStyle
  switch (size) {
    case 'tiny':
      sizedStyle = {
        fontSize: '10px',
        margin: '2px 5px 0px 6px',
      }
      break
    case 'small':
      sizedStyle = {
        fontSize: '13px',
        margin: '1px 0 0 6px',
      }
      break
    case 'medium':
      sizedStyle = {
        fontSize: '17px',
        margin: '0 0 0 4px',
      }
      break
    case 'large':
      sizedStyle = {
        fontSize: '21px',
        margin: '0 0 0 2px',
      }
      break
    default:
      break
  }
  return { ...baseStyle, ...sizedStyle }
}

export const SelectButtonStyle = (buttonId, selectedId) => {
  const baseStyle = {
    display: 'inline-block',
    borderRadius: '6px',
    marginBottom: '8px',
    boxSizing: 'border-box',
    cursor: 'pointer',
  }
  baseStyle.border = buttonId == selectedId ? '3px solid #789CEF' : '3px solid transparent'
  return baseStyle
}

export const ButtonText = {
  display: 'inline-block',
  overflow: 'hidden',
  marginRight: '7px',
  marginLeft: '7px',
}

export const Preview = {
  height: '31px',
  display: 'inline-block',
  width: '85px',
  padding: '6px 0 0 0',
  border: '1px solid #8EACF4',
  float: 'right',
  borderRadius: '4px',
  color: '#8EACF4',
  textAlign: 'center',
  boxSizing: 'border-box',
  fontSize: '13px',
  marginLeft: '5px',
  cursor: 'pointer',
}

export const RestoreDefault = {
  background: '#ffffff',
  border: '1px solid #8EACF4',
  borderRadius: '3px',
  color: '#8EACF4',
  cursor: 'pointer',
  float: 'left',
  fontSize: '14px',
  height: '38px',
  lineHeight: '38px',
  marginBottom: '20px',
  marginRight: '20px',
  textAlign: 'center',
  width: '170px',
}

export const ErrorField = {
  border: '1px solid red',
  outline: 'none',
}

export const ErrorMessage = {
  marginTop: '-20px',
  paddingBottom: '6px',
  color: 'red',
}
