import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import Theme from 'mgr/layout/Theme'
import { tryGetWidgetSettings, tryPostWidgetSettings, updateField, updateTheme, restoreDefault } from '../actions/services'
import * as styles from '../assets/styles'
import { WidgetSettingsForm } from '../components/widgetsettings'

class App extends Component {
  constructor(props) {
    super(props)
  }

  componentWillMount() {
    this.props.actions.tryGetWidgetSettings(this.props.venueId)
  }

  render() {
    return (
      <ThemeProvider theme={Theme}>
        <div>
          <div id="content-header">
            <h2>Waitlist Widget Settings</h2>
          </div>
          <WidgetSettingsForm
            MEDIA_URL={this.props.MEDIA_URL}
            widgetSettings={this.props.widgetSettings}
            appState={this.props.appState}
            actions={this.props.actions}
          />
        </div>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = state => ({
  widgetSettings: state.widgetSettings,
  appState: state.appState,
})

const mapDispatchToProps = dispatch => ({
  actions: {
    updateField: (fieldName, val, error) => {
      dispatch(updateField(fieldName, val, error))
    },
    updateTheme: () => {
      dispatch(updateTheme())
    },
    tryGetWidgetSettings: venueId => {
      dispatch(tryGetWidgetSettings(venueId))
    },
    tryPostWidgetSettings: widgetSettings => {
      dispatch(tryPostWidgetSettings(widgetSettings))
    },
    restoreDefault: () => {
      dispatch(restoreDefault())
    },
  },
})

App = connect(mapStateToProps, mapDispatchToProps)(App)

export default App
