import { combineReducers } from 'redux'
import {
  GETTING_WIDGET_SETTINGS,
  GET_SUCCESS_WIDGET_SETTINGS,
  GET_FAIL_WIDGET_SETTINGS,
  POSTING_WIDGET_SETTINGS,
  POST_SUCCESS_WIDGET_SETTINGS,
  POST_FAIL_WIDGET_SETTINGS,
  UPDATE_FIELD,
} from '../actions/ActionTypes'

const initialWidgetSettingsState = {
  venueId: '',
  textButton: 'Join Waitlist',
  buttonColor: '#093A75',
  buttonId: 1,
  minGuests: 1,
  maxGuests: 20,

  // Text items

  // Labels
  textFirstName: '',
  textLastName: '',
  textEmailAddress: '',
  textPhoneNumber: '',
  textSelectPartySize: '',
  textEstimatedWaitTime: '',
  textAdditionalNotes: '',
  textJoinWaitlist: '',
  textAgreeToWaitlistPolicy: '',
  textVenueClosed: '',
  textVenueClosedCaption: '',

  // Messages
  textVenueGroupMarketingOptIn: '',
  textVenueSpecificMarketingOptIn: '',
}

const widgetSettings = (state = initialWidgetSettingsState, action) => {
  switch (action.type) {
    case GET_SUCCESS_WIDGET_SETTINGS:
      return { ...state, ...action.widgetSettings }
    case UPDATE_FIELD:
      if (action.fieldName === 'minGuests') {
        return { ...state, minGuests: Math.max(Math.min(action.value ?? 1, 20), 1) }
      }
      if (action.fieldName === 'maxGuests') {
        return { ...state, maxGuests: Math.max(Math.min(action.value ?? 1, 20), 1) }
      }
      return { ...state, [action.fieldName]: action.value }
    default:
      return state
  }
}

const initialAppState = {
  isGetting: false,
  isPosting: false,
  isFailure: false,
  errors: {}, // errors for form fields
}
const appState = (state = initialAppState, action) => {
  switch (action.type) {
    case UPDATE_FIELD:
      return {
        ...state,
        errors: { ...state.errors, [action.fieldName]: action.error },
      }
    case GETTING_WIDGET_SETTINGS:
      return { ...state, isGetting: true }
    case GET_SUCCESS_WIDGET_SETTINGS:
      return { ...state, isFailure: false, isGetting: false }
    case GET_FAIL_WIDGET_SETTINGS:
      return { ...state, isFailure: true, isGetting: false }
    case POSTING_WIDGET_SETTINGS:
      return { ...state, isPosting: true }
    case POST_SUCCESS_WIDGET_SETTINGS:
      return { ...state, isFailure: false, isPosting: false }
    case POST_FAIL_WIDGET_SETTINGS:
      return { ...state, isFailure: true, isPosting: false }
    default:
      return state
  }
}

const rootReducer = combineReducers({
  widgetSettings,
  appState,
})

export default rootReducer
