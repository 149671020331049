import { srGet, srPost, srUpload } from '@sevenrooms/core/api'
import {
  GETTING_WIDGET_SETTINGS,
  GET_SUCCESS_WIDGET_SETTINGS,
  GET_FAIL_WIDGET_SETTINGS,
  POSTING_WIDGET_SETTINGS,
  POST_SUCCESS_WIDGET_SETTINGS,
  POST_FAIL_WIDGET_SETTINGS,
  UPDATE_FIELD,
  UPDATE_THEME,
  UPLOAD_PHOTO_PREVIEW,
  UPLOAD_PHOTO_SUCCESS,
  CROP_OPEN,
  CROP_CLOSE,
  CROP_PHOTO,
  CROP_DELETE,
  RESTORE_DEFAULT,
} from './ActionTypes'

// form and app actions

export const updateField = (fieldName, value, error) => ({
  type: UPDATE_FIELD,
  fieldName,
  value,
  error,
})

export const updateTheme = (_fieldName, _value, _error) => ({ type: UPDATE_THEME })

// Actions to retrieve widget settings

export const gettingWidgetSettings = () => ({ type: GETTING_WIDGET_SETTINGS })

export const restoreDefault = () => ({ type: RESTORE_DEFAULT })

export const getSuccessWidgetSettings = widgetSettings => ({
  type: GET_SUCCESS_WIDGET_SETTINGS,
  widgetSettings,
})

export const getFailWidgetSettings = () => ({ type: GET_FAIL_WIDGET_SETTINGS })

export const fetchWidgetSettings = (venueId, errHandler) =>
  srGet('/api-yoa/venue/waitlist_widget_settings', { venue: venueId }).then(response => {
    if (!response.ok && errHandler) {
      errHandler(response)
    }
    return response.data
  })

export const tryGetWidgetSettings = venueId => dispatch => {
  dispatch(gettingWidgetSettings())
  const errHandler = () => dispatch(getFailWidgetSettings())
  fetchWidgetSettings(venueId, errHandler).then(data =>
    dispatch(
      getSuccessWidgetSettings({
        venueId,
        venueGroupMarketingOn: data.widget_settings.venueGroupMarketingOn === 'true',
        tailoredCommunicationOn: data.widget_settings.tailoredCommunicationOn === 'true',
        venueSpecificMarketingOn: data.widget_settings.venueSpecificMarketingOn === 'true',
        venueSmsMarketingOn: data.widget_settings.venueSmsMarketingOn === 'true',
        isVenueGroupMarketingPolicyDefaultOn: data.widget_settings.isVenueGroupMarketingPolicyDefaultOn === 'true',
        isTailoredCommunicationPolicyDefaultOn: data.widget_settings.isTailoredCommunicationPolicyDefaultOn === 'true',
        isTailoredCommunicationPolicyDisabled: data.widget_settings.isTailoredCommunicationPolicyDisabled === 'true',
        isVenueSpecificMarketingPolicyDefaultOn: data.widget_settings.isVenueSpecificMarketingPolicyDefaultOn === 'true',
        isVenueSmsMarketingPolicyDefaultOn: data.widget_settings.isVenueSmsMarketingPolicyDefaultOn === 'true',
        showVenueBanner: data.widget_settings.showVenueBanner === 'true',
        showWaitTimes: data.widget_settings.showWaitTimes === 'true',
        showReservationLink: data.widget_settings.showReservationLink === 'true',
        showMenu: data.widget_settings.showMenu === 'true',
        showMap: data.widget_settings.showMap === 'true',
        showWaitlistSpecialAttentionMessage: data.widget_settings.showWaitlistSpecialAttentionMessage === 'true',
        showWaitingRoomSpecialAttentionMessage: data.widget_settings.showWaitingRoomSpecialAttentionMessage === 'true',
        hideQuoteTimes: data.widget_settings.hideQuoteTimes === 'true',
        minimumWaitShown: data.widget_settings.minimumWaitShown,
        waitTimeBuffer: data.widget_settings.waitTimeBuffer.toString(),
        contactUs: data.widget_settings.contactUs,
        showArrivalTimeType: data.widget_settings.showArrivalTimeType,
        minGuests: data.widget_settings.min_guests,
        maxGuests: data.widget_settings.max_guests,
        buttonColor: data.widget_settings.color_button || '',
        buttonId: data.widget_settings.button_id || '1',
        isSmsMarketingEnabled: data.is_sms_marketing_enabled === 'true',
      })
    )
  )
}

// Actions to save widget settings

export const postingWidgetSettings = () => ({ type: POSTING_WIDGET_SETTINGS })

export const postSuccessWidgetSettings = () => ({
  type: POST_SUCCESS_WIDGET_SETTINGS,
})

export const postFailWidgetSettings = () => ({
  type: POST_FAIL_WIDGET_SETTINGS,
})

export const saveWidgetSettings = (venueId, widgetSettings, errHandler) =>
  srPost('/api-yoa/venue/waitlist_widget_settings', { venue: venueId, ...widgetSettings }).then(response => {
    if (!response.ok && errHandler) {
      errHandler(response)
    }
    return {}
  })

export const tryPostWidgetSettings = widgetSettings => dispatch => {
  dispatch(postingWidgetSettings())
  const errHandler = () => dispatch(postFailWidgetSettings())
  saveWidgetSettings(
    widgetSettings.venueId,
    {
      venue_id: widgetSettings.venueId,
      widget_template_theme: widgetSettings.widgetTemplateTheme,
      venueGroupMarketingOn: widgetSettings.venueGroupMarketingOn,
      tailoredCommunicationOn: widgetSettings.tailoredCommunicationOn,
      venueSpecificMarketingOn: widgetSettings.venueSpecificMarketingOn,
      venueSmsMarketingOn: widgetSettings.venueSmsMarketingOn,
      isVenueGroupMarketingPolicyDefaultOn: widgetSettings.isVenueGroupMarketingPolicyDefaultOn,
      isTailoredCommunicationPolicyDefaultOn: widgetSettings.isTailoredCommunicationPolicyDefaultOn,
      isTailoredCommunicationPolicyDisabled: widgetSettings.isTailoredCommunicationPolicyDisabled,
      isVenueSpecificMarketingPolicyDefaultOn: widgetSettings.isVenueSpecificMarketingPolicyDefaultOn,
      isVenueSmsMarketingPolicyDefaultOn: widgetSettings.isVenueSmsMarketingPolicyDefaultOn,
      showVenueBanner: widgetSettings.showVenueBanner,
      minimumWaitShown: widgetSettings.minimumWaitShown,
      waitTimeBuffer: parseInt(widgetSettings.waitTimeBuffer, 10),
      showWaitTimes: widgetSettings.showWaitTimes,
      showReservationLink: widgetSettings.showReservationLink,
      showMenu: widgetSettings.showMenu,
      showMap: widgetSettings.showMap,
      showWaitlistSpecialAttentionMessage: widgetSettings.showWaitlistSpecialAttentionMessage,
      showWaitingRoomSpecialAttentionMessage: widgetSettings.showWaitingRoomSpecialAttentionMessage,
      hideQuoteTimes: widgetSettings.hideQuoteTimes,
      contactUs: widgetSettings.contactUs,
      showArrivalTimeType: widgetSettings.showArrivalTimeType,
      min_guests: widgetSettings.minGuests,
      max_guests: widgetSettings.maxGuests,
      color_button: widgetSettings.buttonColor,
      button_id: widgetSettings.buttonId,
    },
    errHandler
  ).then(() => dispatch(postSuccessWidgetSettings()))
}

export const uploadPhotoPreview = (imageType, preview) => ({
  type: UPLOAD_PHOTO_PREVIEW,
  imageType,
  preview,
})

export const uploadPhotoSuccess = (imageType, upload) => ({
  type: UPLOAD_PHOTO_SUCCESS,
  imageType,
  upload,
})

export const uploadPhoto = (imageType, file) => (dispatch, _getState) => {
  if (!['image/jpeg', 'image/png', 'image/gif', 'image/tiff'].includes(file.type)) {
    Interface._alert('Image type not acceptable')
    return
  }
  dispatch(uploadPhotoPreview(imageType, file.preview))
  dispatch(cropOpen(imageType))
  // eslint-disable-next-line consistent-return
  return srPost('/upload-url', { rurl: Math.random().toString() }, { convertToGetParams: true })
    .then(response => (response.error ? response : srUpload(response.upload_url, file)))
    .then(uploadData => {
      dispatch(uploadPhotoSuccess(imageType, uploadData))
    })
}

export const cropOpen = imageType => ({ type: CROP_OPEN, imageType })

export const cropImage = (imageType, coords) => ({
  type: CROP_PHOTO,
  imageType,
  coords,
})

export const closeCrop = imageType => ({ type: CROP_CLOSE, imageType })

export const cropDelete = imageType => ({ type: CROP_DELETE, imageType })
